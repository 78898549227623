import { Grid } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Register from "./views/public/Register";
import Login from "./views/public/Login";
import Auth from "./views/public/Auth";
import WithAuth from "./component/WithAuth";
import LayOut from "./views/private/LayOuth";
import { useBearStore } from "./store";
import shallow from 'zustand/shallow'
import React from "react";

function App() {
  let { user } = useBearStore(
    (state: { user: any; }) => ({ user: state.user }),
    shallow
  );
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin:2,
        // background: " linear-gradient(145deg, #cef4d4 0, #00ae3d 150%)",
        height: { xs: '110vh', sm: "110vh", m:"100vh" },
        // 
      }}
    >
      <Routes>
        {!user && <Route
          path={"/login"}
          element={
            <Auth name={"Login"} info={"Input your login details"}>
              <Login />
            </Auth>
          }
        />}
        {!user && <Route
          path={"/register"}
          element={
            <Auth
              name={"Register"}
              info={"Enter your details"}
              children={<Register />}
            />
          }
        />}
        <Route
          path="*"
          element={
            <WithAuth>
             <LayOut />
            </WithAuth>
          }
        />
      </Routes>
    </Grid>
  );
}
export default App;