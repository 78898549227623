import * as React from "react";
import { useSnackbar } from 'notistack';



export default function UseSnackbar() {
const { enqueueSnackbar} = useSnackbar();  

const toggleSnack =  (message,variant= "error") => {
    enqueueSnackbar(message,{variant:variant,anchorOrigin:{
        horizontal:"center",
        vertical:"top"

    }});
}

return toggleSnack
}
