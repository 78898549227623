import Grid2 from "@mui/material/Unstable_Grid2";
import { Routes, Route, useNavigate } from "react-router-dom";
import HorizontalLabelStepper from "../../component/Stepper";
import { privateRoutes } from "../../routes";
import React, { useState } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function LayOut() {
  const [anchorELement, setAnchorElement] = useState<null | HTMLElement>(null);
  const navigate = useNavigate()
  const open = Boolean(anchorELement);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorElement(null);
  }
  const handleChangePassword = () => {
    setAnchorElement(null);
  }
  const handleLogOut = () => {
    setAnchorElement(null);
    localStorage.clear();
    window.location.reload()
  }
  const getRoutes = (allRoutes: any[]) =>
    allRoutes.map((route, index) => (<Route path={route.path} element={route.element} key={index + 1} />))

  return (
    <Grid2
      container
      flexDirection={"column"}
      flexWrap={"nowrap"}
      gap={2}

      sx={{
        padding: 3,
        minHeight:500,
        height: { xs: 'auto', sm: 'auto' },
        width: 700,
        background: "#ffffff",
        opacity: 0.8,
        backdropFilter: "blur(10px)",
        borderRadius: 3,
        color: "black",
        m: 2,
      }}
    >
      <HorizontalLabelStepper />
      <Box display="flex" justifyContent="flex-end" >
        <Button id="profile-dropdown" onClick={handleClick} variant="text" sx={{ color: "green", fontSize: 10, mt: -3, mb: -2 }}>Profile<ArrowDropDownIcon /></Button>
      </Box>
      <Menu
        id="profile-dropdown"
        anchorEl={anchorELement}
        open={open}
        onClose={handleClose}
        sx={{
          fontSize: 10,
          mt: -1.5,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleChangePassword} sx={{ fontSize: 10, m: { xs: -1, sm: -1 }, mt: { xs: -2 } }}>Change Password</MenuItem>
        <MenuItem onClick={handleLogOut} sx={{ fontSize: 10, m: { xs: -1, sm: -1 }, mt: { xs: -3 }, mb: { xs: -1 } }}>Logout</MenuItem>
      </Menu>
      <Routes>
        {getRoutes(privateRoutes)}
      </Routes>
    </Grid2>
  );
}

export default LayOut;
