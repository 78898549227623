import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const Summary = ()=>{
    return(
        <>
        <Grid container flexDirection={"column"} flexWrap={"nowrap"} gap={1}>
            <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign={"center"}
            >
                Success Page
            </Typography>
            <Typography gutterBottom component="div" textAlign={"center"}>
                Congratulations on your successful application. 
            </Typography>
        </Grid>
        </>
    )
}
export default Summary;