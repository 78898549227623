export const programmeList = [
    {
        programe: "Small Scale Enterprise(SSE)",
        skills: [
            "Micro Enterprises Enhancement Scheme (MEES)", 
            "Enterprises Creation Fund (ECF)", 
            "Micro Business Startup Training Scheme (MBSTS)",
            "Collaborative Training",
            "Enterprises Start up Development Training Scheme (ESDTS)",
            "Small and Micro Enterprises Scheme (SMES)",
            "Women Employment Branch (WEB)"
        ]
    },
    {
        programe: "Vocational Skills Development(VSD)",
        skills: [
            "Basic National Open Apprenticeship Scheme (NOAS)",
            "Cosmetology Skills",
            "Advance National Open Apprenticeship Scheme (A-NOAS)",
            "School-On-Wheel (SOW) Scheme",
            "Resettlement Loan Scheme (RLS)"
        ]
    },
    {
        programe: "Special Public Works(SPW)",
        skills: [
            "Graduate Attachment Programme (GAP)",
            "Environmental Beautification Training Scheme (EBTS)",
            "Community Development Scheme (CDS)",
            "Solar Energy Training Scheme (SETS)",
            "Graduate Coaching Scheme (GCS)",
            "Graduate Special Training Programme (GSTP)",
            "Collaborative Employment Scheme (CES)"
        ]
    },
    {
        programe: "Rural Employment Promotion(REP)",
        skills: [
            "Agricultural Development Training Scheme (SADTS)",
            "Agricultural Value Chain Scheme (AVCS)",
            "Agricultural Enhancement Scheme (AES)",
            "Food Processing and Packaging Scheme (FPPS)"
        ]
    }
]    