import {
    Box,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { stateList } from "../../assets/stateList";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CheckIcon from '@mui/icons-material/Check';
import React from "react";
import { z } from "zod";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CustomButton } from "../../component/Custombutton";
import { useBearStore } from "../../store";
import UseSnackbar from "../../component/SnackBar";
import { config } from "../../config";
import SimpleBackdrop from "../../component/backdrop";



const biodataSchema = z.object({
    lastName: z.string().regex(/^[A-Za-z]+(?:[ -][A-Za-z]+)*$/, 'Only alphabets allowed').min(3, "3 or more characters"),
    firstName: z.string().regex(/^[A-Za-z]+(?:[ -][A-Za-z]+)*$/, 'Only alphabets allowed').min(3, "3 or more characters"),
    // email: z.string().email({ message: "Invalid email address" }),
});

type biodataInput = z.infer<typeof biodataSchema>;

const PersonalInfo = () => {
    const { NextPage } = useBearStore();
    const [profilePicture, setProfilePicture] = useState<any>("");
    const [isNIN, setIsNIN] = useState("")
    const [ninNumber, setninNumber] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [steps, setSteps] = useState(1);
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState<any>("");
    const [genderError, setGenderError] = useState(true);
    const [DoB, setDoB] = useState("");
    const [stateOrigin, setStateOrigin] = useState<any>("");
    const [lgaorigin, setLGAStateOrigin] = useState<any>("");
    const [stateResidence, setStateResidence] = useState<any>("");
    const [lgastateResidence, setLGAStateResidence] = useState<any>("");
    const [btnLoading, setBtnLoading] = useState(false);
    const [address, setAddress] = useState("");
    const toggleSnack = UseSnackbar()

    const handleGenderChange = (e) => {
        setGender(e.target.value)
        setGenderError(false);
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<biodataInput>({ resolver: zodResolver(biodataSchema), });

    useEffect(()=>{
        let user:any = localStorage.getItem('user')
        let email = JSON.parse(user).user.email
        setEmail(email)
        
    },[])

    const onSubmitBiodataData = () => {

        if (!profilePicture) {
            toggleSnack('Please upload your passport photo.');
        } else if (isNIN === "invalid NIN") {
            toggleSnack('Please enter a valid NIN.');
        } else if (genderError) {
            toggleSnack('Please select your gender.');
        } else {

            let userDetail:any = localStorage.getItem('user')
            const token = JSON.parse(userDetail).token
                setBtnLoading(true)
            fetch(`${config.baseUrl}/biodata`,{
                method:"POST",
            body:JSON.stringify({
                    profilePicture,
                    ninNumber,
                    lastName,
                    firstName,
                    gender,
                    DoB,
                    steps,
                    stateOrigin,
                    lgaorigin,
                    stateResidence,
                    lgastateResidence,
                    address}),
                headers:{
                    "Content-Type":"application/json",
                    "x-auth-token":`${token}`
                }
            }).then(async res=>{
                let result = await res.json()
                setBtnLoading(false)  
                const{ok} = result
                if(ok){
                    localStorage.setItem("steps", `${JSON.stringify({steps:result})}`);
                    toggleSnack("Biodata successfully updated","success")
                    NextPage();
                }
            }).catch(err=>{
                setBtnLoading(false)
                toggleSnack(err.message)
            })
            
        }
    }

    useEffect(() => {
        if (ninNumber.length === 11) {
            setIsNIN("loading");
        } else {
            setIsNIN("invalid NIN");
        }
    }, [ninNumber]);

    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    const changeHandler = async (e: any) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            toggleSnack("Image mime type is not valid");
            return;
        }
        if (file.size > 1000000) {
            toggleSnack("Maximum image size is 1MB");
            return;
        }
        // const objectUrl = URL.createObjectURL(file);
        const objectUrl = await convertBase64(file)
        setProfilePicture(objectUrl);
    };

    const convertBase64 = (file:any)=>{
        return new Promise((resolve,reject)=>{
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file)

            fileReader.onload =()=>{
                resolve(fileReader.result)
            }

            fileReader.onerror = error=>{
                reject(error)
            }
        })
    }

    
    

    return (
        <>  
        <SimpleBackdrop open={btnLoading} setOpen={setBtnLoading}/>     
        <Grid container flexDirection={"column"} flexWrap={"nowrap"}>
            <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign={"center"}
            >
                Bio-data
            </Typography>
            <Box
                component='form'
                onSubmit={handleSubmit(onSubmitBiodataData)}
            >
                <Grid item container flexDirection={{ xs: "column", sm: "row" }} flexWrap={"nowrap"} gap={1} >
                    <Grid item xs={12} sm={3}>
                        <Box display={"flex"} justifyContent={"center"}>
                            <Grid
                                item
                                sx={{
                                    bgcolor: "#ccccac9b",
                                    border: "1px solid #00000014",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    width: { xs: 100, sm: 120 },
                                    height: { xs: 110, sm: 130 },
                                }}
                            >
                                {profilePicture ? (<img width={120} height={128} src={profilePicture} alt="avater" />)
                                    : (
                                        <Box display="flex" justifyContent={"center"} height="100%">
                                            <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="label"
                                            >
                                                <input
                                                    onChange={changeHandler}
                                                    hidden
                                                    accept="image/*"
                                                    type="file"
                                                />
                                                <AddAPhotoIcon />
                                            </IconButton>
                                        </Box>
                                    )}
                            </Grid>
                        </Box>
                        <Grid>
                            {profilePicture &&
                                <Box display="flex" justifyContent={"center"} mt={{ xs: 0, sm: -2 }}>
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                    >
                                        <input
                                            onChange={changeHandler}
                                            hidden
                                            accept="image/*"
                                            type="file"
                                        />
                                        <AddAPhotoIcon />
                                    </IconButton>
                                </Box>
                            }
                        </Grid>
                        <Typography color={"grey"} fontSize={10} align={"center"} >
                            Supported fomat jpeg/png/jpg
                        </Typography>
                        <Typography mb={2} fontSize={10} align={"center"}>
                            max size 1mb
                        </Typography>
                        <Box display={"flex"} justifyContent={"center"}>
                            <TextField
                                variant="outlined"
                                label="NIN"
                                size="small"
                                type="number"
                                required
                                // onWheel={() => document.activeElement.blur()}
                                value={ninNumber}
                                onChange={e => setninNumber((e.target.value))}
                                sx={{ maxWidth: 170, }}
                                fullWidth
                                error={isNIN === "invalid NIN" ? true : false}
                                InputProps={{
                                    endAdornment:
                                        isNIN === "ok" ? (
                                            <CheckIcon color="success" />
                                        ) : isNIN === "loading" ? (
                                            <CircularProgress size={20} color="success" />
                                        ) : (
                                            ""
                                        ),
                                }}
                            />
                        </Box>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Divider orientation="horizontal" flexItem />

                    <Grid item container xs={12} sm={9} flexDirection={"column"} flexWrap={"nowrap"} gap={1}>
                        <Grid item container gap={2} flexDirection={{ xs: "column", sm: "row" }} flexWrap={"nowrap"}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    label="Last Name"
                                    size="small"
                                    type="text"
                                    fullWidth
                                    required
                                    value={lastName}
                                    error={!!errors['lastName']}
                                    helperText={errors['lastName'] ? errors['lastName'].message : ''}
                                    {...register('lastName', {
                                        onChange: (e) => setLastName(e.target.value),
                                        // pattern: /^[a-zA-Z]+$/
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    label="First Name"
                                    size="small"
                                    type="text"
                                    fullWidth
                                    required
                                    value={firstName}
                                    error={!!errors['firstName']}
                                    helperText={errors['firstName'] ? errors['firstName'].message : ''}
                                    {...register('firstName', {
                                        onChange: (e) => setFirstName(e.target.value)
                                    })}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container flexDirection={{ xs: "column", sm: "row" }} flexWrap={"nowrap"} gap={2}>
                            <Grid item xs={12} sm={6} mt={{ xs: 1, sm: 2.5 }} >
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    required
                                    value={email}
                                    // error={!!errors['email']}
                                    // helperText={errors['email'] ? errors['email'].message : ''}
                                    // {...register('email', {
                                    //     onChange: (e) => setEmail(e.target.value)
                                    // })}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                        Gender
                                    </FormLabel>
                                    <RadioGroup
                                        sx={{
                                            display: "flex",
                                            flexWrap: "noWrap",
                                        }}
                                        row
                                        value={gender}
                                        onChange={handleGenderChange}
                                    >
                                        <FormControlLabel
                                            value="female"
                                            control={<Radio size="small" />}
                                            label="Female"
                                        />
                                        <FormControlLabel
                                            value="male"
                                            control={<Radio size="small" />}
                                            label="Male"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container gap={2} flexDirection={{ xs: "column", sm: "row" }} flexWrap={"nowrap"}>
                            <Grid item xs={12} sm={6} >
                                <FormControl fullWidth>
                                    <FormLabel sx={{ mb: 1 }} id="demo-row-radio-buttons-group-label">
                                        Date of Birth
                                    </FormLabel>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        type="date"
                                        required
                                        value={DoB}
                                        onChange={(e) => setDoB(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item container xs={12} sm={6} >
                                <FormControl fullWidth>
                                    <FormLabel sx={{ mb: 1 }}>State of Origin</FormLabel>
                                    <Grid item container flexDirection={"row"} flexWrap={"nowrap"} gap={1}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="demo-select-small"
                                                label="State"
                                                size="small"
                                                select
                                                required
                                                value={stateOrigin}
                                                onChange={(e) => setStateOrigin(e.target.value)}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {stateList.map((state) => (
                                                    <MenuItem key={state.state} value={state.state}>{state.state}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="demo-select-small"
                                                label="LGA"
                                                size="small"
                                                select
                                                required
                                                value={lgaorigin}
                                                onChange={(e) => setLGAStateOrigin(e.target.value)}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {stateList.map(id => id.state === stateOrigin && (id.lgas.map(lga => (<MenuItem value={lga}>{lga}</MenuItem>))))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Grid item container gap={2} flexDirection={"column"} flexWrap={"nowrap"}>
                            <Grid item container gap={2} flexDirection={"row"} flexWrap={"nowrap"} >
                                <FormControl fullWidth>
                                    <FormLabel sx={{ mb: 1 }}>Address</FormLabel>
                                    <Grid item container gap={2} flexDirection={"row"} flexWrap={"nowrap"} >
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="State"
                                                size="small"
                                                select
                                                required
                                                value={stateResidence}
                                                onChange={(e) => setStateResidence(e.target.value)}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {stateList.map((state) => (
                                                    <MenuItem key={state.state} value={state.state}>{state.state}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="demo-select-small"
                                                label="LGA"
                                                size="small"
                                                select
                                                required
                                                value={lgastateResidence}
                                                onChange={(e) => setLGAStateResidence(e.target.value)}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {stateList.map(id => id.state === stateResidence && (id.lgas.map(lga => (<MenuItem value={lga}>{lga}</MenuItem>))))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Address"
                                    rows={2}
                                    multiline
                                    size="small"
                                    fullWidth
                                    required
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid container flexDirection={"row"} flexWrap={"nowrap"} justifyContent={"flex-end"} mt={2}>
                    <CustomButton
                        type="submit"
                        // onClick={NextPage}
                        // disabled={button}
                        // loading={btnLoading}
                        disableFocusRipple
                        disableRipple
                    >
                        Next
                    </CustomButton>
                </Grid>
            </Box>
        </Grid >
        </> 
    );
}

export default PersonalInfo;
