import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBearStore } from '../store';


const steps = [
    'Bio-data',
    'Education',
    'Programme',
    'Attestation',
    'Summary',
];

export default function HorizontalLabelStepper() {
    const { page } = useBearStore();
    const navigate = useNavigate();

        const countSteps:any = localStorage.getItem("steps")
        let step = JSON.parse(countSteps)?.steps?.user?.steps
    useEffect(() => { 
        switch (step) {
            case 0:
                
                navigate("/biodata");
                break;
            case 1:
                
                navigate("/education");
                break;
            case 2:
                
                navigate("/programme");
                break;
            case 3:
                
                navigate("/referees");
                break;
            case 4:
                
                navigate("/summary");
                break;
            default:
                
                navigate("/login");
                break;
        }
    }, [step])


    return (
        <Box sx={{
            width: '100%',
            height: 10,
            mb: 8,
            mt: 1,

        }}>
            <Stepper activeStep={step} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}