import { Grid, InputAdornment, Slide, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CustomButton } from "../../component/Custombutton";
import { z } from "zod";
import { config } from "../../config";
import UseSnackbar from "../../component/SnackBar";
import SimpleBackdrop from "../../component/backdrop";

const Register = ({ name, info, children }: any) => {
  const [submitState, setSubmitState] = useState("register");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnState, setBtnState] = useState(true);
  const [open, setOPen] = useState(false)
  const navigate = useNavigate();
  const toggleSnack = UseSnackbar();

  const emailAddress = z
    .string({
      required_error: "Email is required",
      invalid_type_error: "Invalid input",
    })
    .email({ message: "Invalid email address" });

  // phone schema type with error response
  const phoneNumber = z
    .string({
      required_error: "Phone is required",
      invalid_type_error: "Invalid input",
    })
    .startsWith("0", {
      message: "Invalid phone number, must start with 0",
    })
    .max(11, {
      message: "Must be an 11-digit number",
    });

  // email schema type with error response

  //OTP code schema
  const OTPcode = z.string().length(6, {
    message: "Must be a 6-digit number",
  });

  //set password schema
  const loginPassword = z
    .string()
    .min(6, "Password must be between 6 to 20 characters")
    .max(20, "Password must be between 6 to 20 characters");

  const handlePhoneRegister = () => {
    if (phone && !phoneError) {
      //toggle loading button
      setBtnLoading(true);
      fetch(`${config.baseUrl}/check`, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          phone,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (!response.ok) {
            setBtnLoading(false);
            return toggleSnack(response.message);
          }
          setBtnLoading(false);
          setSubmitState("otp");
        })
        .catch((err) => {
          toggleSnack(err.message);
          setBtnLoading(false);
        });
    }
  };

  const handleOTPregister = () => {
    if (phone && !phoneError && code && !codeError) {
      setBtnLoading(true);
      fetch(`${config.baseUrl}/api/v1/auth/verify`, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          phone,
          code,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (!response.ok) return toggleSnack(response.message);
          setBtnLoading(false);
          setSubmitState("register");
        })
        .catch((err) => {
          toggleSnack(err.message);
          setBtnLoading(false);
        });
    }
  };

  const handleRegister = () => {
    if (!email) return setEmailError("email is required");
    if (!phone) return setPhoneError("phone number is required");
    if (!password.length) return setPasswordError("password required");
    if (!confirmPassword.length) return setConfirmPasswordError("incorrect password");

    setBtnLoading(true);
    
   
    console.log({ password, phone, email });
    fetch(`${config.baseUrl}/register`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        phone,
        password,
        email,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.ok){
          setBtnLoading(false);
          
          return toggleSnack(response.message);
        } 
        setBtnLoading(false);
        toggleSnack("Registration successful!", "success");
        navigate("/");
      })
      .catch((err) => {
        setBtnLoading(false);
        toggleSnack(err.message);
        
      });
  };

  // useEffect(() => {
  //   //reseting error message when user start typing
  //   setPhoneError("");
  //   if (phone) {
  //     let type = phoneNumber.safeParse(phone);
  //     setTimeout(() => {
  //       if (!type.success) {
  //         let erros = type.error.format()._errors[0];
  //         setPhoneError(erros);
  //       }
  //     }, 1000);
  //   }
  // }, [phone]);

  // useEffect(() => {
  //   //reseting error message when user start typing
  //   setEmailError("");
  //   if (email) {
  //     let type = emailAddress.safeParse(email);
  //     setTimeout(() => {
  //       if (!type.success) {
  //         let erros = type.error.format()._errors[0];
  //         setEmailError(erros);
  //       }
  //     }, 1000);
  //   }
  // }, [email]);

  // useEffect(() => {
  //   //reseting error message when user start typing
  //   setCodeError("");
  //   if (code) {
  //     let type = OTPcode.safeParse(code);
  //     if (!type.success) {
  //       setCodeError(type.error.format()._errors[0]);
  //     }
  //   }
  // }, [code]);

  useEffect(() => {
    //reseting error message when user start typing
    setPasswordError("");
    if (password) {
      let type = loginPassword.safeParse(password);
      setTimeout(() => {
        if (!type.success) {
          setPasswordError(type.error.format()._errors[0]);
        }
      }, 1000);
    }
  }, [password]);

  useEffect(() => {
    //reseting error message when user start typing
    setConfirmPasswordError("");
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
    }
  }, [confirmPassword]);

  return (
    <React.Fragment>
      <>
        <SimpleBackdrop open={btnLoading} setOpen={setBtnLoading}/>
        <Grid container flexDirection={"row"} gap={2} sx={{ mb: 1 }}>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              label="Email"
              size="small"
              type="email"
              helperText={emailError}
              error={emailError ? true : false}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e: any) => setPhone(e.target.value)}
              variant="outlined"
              label="Phone Number"
              size="small"
              type="text"
              helperText={phoneError}
              error={phoneError ? true : false}
              fullWidth
              sx={{
                mb: 1,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              label="Password"
              size="small"
              type="password"
              helperText={passwordError}
              error={passwordError ? true : false}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => setConfirmPassword(e.target.value)}
              variant="outlined"
              label="Confirm Password"
              size="small"
              type="password"
              helperText={confirmPasswordError}
              error={confirmPasswordError ? true : false}
              fullWidth
            />
          </Grid>
        </Grid>
        <CustomButton loading={btnLoading} fullWidth onClick={handleRegister}>
          Register
        </CustomButton>
      </>

      <span
        style={{
          textDecoration: "none",
          textAlign: "center",
          color: "green",
          fontSize: "13px",
        }}
      >
        Already have an account?
        <Link
          style={{
            textDecoration: "none",
            textAlign: "center",
            color: "green",
            fontSize: "12px",
            fontWeight: "bolder",
          }}
          to={"/login"}
        >
          {" "}
          Click Here
        </Link>
      </span>
    </React.Fragment>
  );
};

export default Register;
