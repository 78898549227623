import create from "zustand";

export const useBearStore = create((set: any) => ({
  page: 0,
  user: localStorage.getItem("user") || null,
  forms:{},
  setForm:(data)=>set(()=> ({data:data})),
  setUser: (user: any) => set(() => ({ user: user })),
  setPage: (currentPage: number) => set(() => ({ page: currentPage })),
  NextPage: () => set((state: any) => state.page < 3 ? { page: state.page + 1 } : null),
  PreviousPage: () => set((state: any) => state.page > 0 ? { page: state.page - 1 } : null),
}));