import {
    Box,
    Button,
    Divider,
    FormLabel,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import { useState } from "react";
import { CustomButton } from "../../component/Custombutton";
import DeleteIcon from '@mui/icons-material/Delete';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React from "react";
import { nanoid } from "nanoid";
import { useBearStore } from "../../store";
import UseSnackbar from "../../component/SnackBar";
import { config } from "../../config";
import SimpleBackdrop from "../../component/backdrop";

const education = [
    "Primary School",
    "SSCE",
    "ND",
    "HND",
    "Degree"
]

const educationSchema = z.object({
    qualification: z.enum(["Primary School", "SSCE", "ND", "HND", "Degree"]),
    institution: z.string().min(6, '6 or more characters'),
    startDate: z.string(),
    endDate: z.string(),
});

type educationInput = z.infer<typeof educationSchema>;

const Education = () => {
    const { NextPage, PreviousPage } = useBearStore();
    const [qualification, setQualification] = useState<any>("");
    const [institution, setInstitution] = useState<any>("");
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [steps, setSteps] = useState(2);
    const [allqualifications, setAllQualifications] = useState<any>([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const toggleSnack = UseSnackbar()

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<educationInput>({ resolver: zodResolver(educationSchema), });

    const onSubmitEducationData = (data: educationInput) => {

        const check = allqualifications.map((obj: any) => {
            if (obj.qualification === data.qualification) {
                return false;
            }
            return true;
        })
        if (check.includes(false)) return toggleSnack("Duplicate qualification is not allowed");
        const Qualification = {
            id: nanoid(),
            qualification,
            institution,
            startDate,
            endDate,
        }
        const newQualification = [Qualification, ...allqualifications];
        setAllQualifications(newQualification);
        setInstitution('');
        setQualification('');
        setEndDate('');
        setStartDate('');
    }

    const handleNextPage = () => {
        if (allqualifications.length !== 0) {

            let userDetail:any = localStorage.getItem('user')
            const token = JSON.parse(userDetail).token
            setBtnLoading(true) 
            fetch(`${config.baseUrl}/education`,{
                method:"POST",
                body:JSON.stringify({ qualifications: allqualifications,steps }),
                headers:{
                    "Content-Type":"application/json",
                    "x-auth-token":`${token}`
                }
            }).then(async res=>{
                let result = await res.json()
                setBtnLoading(false) 
               
                const{ok} = result
                if(ok){
                    localStorage.setItem("steps", `${JSON.stringify({steps:result})}`);
                    toggleSnack("education successfully updated","success")
                    NextPage();
                }
            }).catch(err=>{
                setBtnLoading(false) 
                toggleSnack(err.message)
            })
            
        } else {
            toggleSnack("Add at least one qualification");
        }
    }

    const deleteQualification = (id) => {
        const remainingQualification = allqualifications.filter(quali => id !== quali.id);
        setAllQualifications(remainingQualification);
    }

    return (
        <>
        <SimpleBackdrop open={btnLoading} setOpen={setBtnLoading}/>
        <Grid container flexDirection={"column"} flexWrap={"nowrap"} gap={2}>
            <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign={"center"}
            >
                Education
            </Typography>
            <Box component='form'
                onSubmit={handleSubmit(onSubmitEducationData)}
            >
                <Grid container flexDirection={"column"} flexWrap={"nowrap"} gap={2}>

                    <Grid container flexDirection={"column"} flexWrap={"nowrap"} gap={2}>
                        <Grid container flexDirection={{ xs: "column", sm: "row" }} flexWrap={"nowrap"} gap={2} >
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    id="demo-select-small"
                                    label="Qualification"
                                    select
                                    size="small"
                                    value={qualification}
                                    required
                                    fullWidth
                                    error={!!errors['qualification']}
                                    helperText={errors['qualification'] ? errors['qualification'].message : ''}
                                    {...register('qualification', {
                                        onChange: (e) => setQualification(e.target.value)
                                    })}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {education.map((state) => (
                                        <MenuItem key={state} value={state}>{state}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    size={"small"}
                                    label="Institution"
                                    value={institution}
                                    required
                                    error={!!errors['institution']}
                                    helperText={errors['institution'] ? errors['institution'].message : ''}
                                    {...register('institution', {
                                        onChange: (e: any) => setInstitution(e.target.value)
                                    })}
                                />
                            </Grid>
                        </Grid>

                        <Grid container flexDirection={{ xs: "column", sm: "row" }} flexWrap={"nowrap"} gap={2} >
                            <Grid item xs={12} sm={6}>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                    Start Date
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    type="month"
                                    size={"small"}
                                    required
                                    value={startDate}
                                    error={!!errors['startDate']}
                                    helperText={errors['startDate'] ? errors['startDate'].message : ''}
                                    {...register('startDate', {
                                        onChange: (e: any) => setStartDate(e.target.value)
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                    End Date
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    type="month"
                                    size={"small"}
                                    required
                                    value={endDate}
                                    error={!!errors['endDate']}
                                    helperText={errors['endDate'] ? errors['endDate'].message : ''}
                                    {...register('endDate', {
                                        onChange: (e: any) => setEndDate(e.target.value)
                                    })}
                                />
                            </Grid>
                        </Grid>
                        <Box display={"flex"} justifyContent={"center"}>
                            <Grid container item xs={6} flexWrap={"nowrap"}>
                                <CustomButton fullWidth size={"small"} type="submit">
                                    Add
                                </CustomButton>
                            </Grid>
                        </Box>
                    </Grid>

                    <Divider orientation="horizontal" variant="middle" flexItem />
                    <Grid item xs={12}>
                        <Table rows={allqualifications} deleteQualification={deleteQualification} />
                    </Grid>
                </Grid>
                <Grid container flexDirection={"row"} flexWrap={"nowrap"} justifyContent={"space-between"} mt={2}>
                    <Button
                        variant="text"
                        onClick={PreviousPage}
                        sx={{ color: "green" }}
                    > Back
                    </Button>
                    <CustomButton
                        onClick={handleNextPage}
                        // disabled={button}
                        // loading={btnLoading}
                        disableFocusRipple
                        disableRipple
                    >
                        Next
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
        </>
    );
}

export default Education;

const Table = ({ rows, deleteQualification }: any) => {
    return (
        <Grid

            sx={{
                border: "2px solid #00000037",
                borderRadius: 1,
                height: 120,
                overflowY: "scroll",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                    width: 10,
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "#3d924075"
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#50a05c",
                    borderRadius: 2
                }
            }}
        >
            <Grid
                sx={{ backgroundColor: "#3d924075", padding: "1px 3px", }}
                flexWrap={"nowrap"}
                container
                display='flex'
                justifyContent="space-between"
                position={"sticky"}

            >
                <Grid component={"span"} width={120} sx={{ ml: { xs: 1, sm: 2 } }} >Qualification</Grid>
                <Grid component={"span"} width={80} sx={{ ml: { xs: -1, sm: -2 } }} >End Date</Grid>
                <Grid></Grid>
            </Grid>
            {
                rows.map((row: any) => (
                    <div key={row.id}>
                        <Grid
                            sx={{ padding: "1px 3px", ml: 2 }}
                            flexWrap={"nowrap"}
                            container
                            display='flex'
                            justifyContent="space-between"
                            fontSize={12}

                        >
                            <Typography width={140} fontSize={12} sx={{ ml: { xs: -1, sm: 0 } }} >{row.qualification}</Typography>
                            <Typography width={80} fontSize={12} sx={{ ml: { xs: -1, sm: 1 } }} >{row.enddate}</Typography>
                            <Grid > <DeleteIcon onClick={() => deleteQualification(row.id)} sx={{ fontSize: "15px", mr: { xs: 1.5, sm: 4 }, cursor: 'pointer', }} color="error" /> </Grid>
                        </Grid>
                        <Divider orientation="horizontal" variant="middle" flexItem sx={{ mt: -1, }} />
                    </div>
                ))
            }
        </Grid>
    );
};