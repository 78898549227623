import { Login } from "@mui/icons-material";
import Education from "./views/private/Education";
import PersonalInfo from "./views/private/PersonalInfo";
import Referees from "./views/private/Refrees";
import Register from "./views/public/Register";
import React from "react";
import Programme from "./views/private/Programme";
import Summary from "./views/private/summary";

export const privateRoutes = [
    {
        name: "Bio data",
        path: "/biodata",
        element: <PersonalInfo />,

    },
    {
        name: "Education",
        path: "/education",
        element: <Education />,

    },
    {
        name: "Programme",
        path: "/programme",
        element: <Programme />,

    },
    {
        name: "Referees",
        path: "/referees",
        element: <Referees />,

    },
    {
        name: "Summary",
        path: "/summary",
        element: <Summary />,
    },

];

export const publicRoutes = [

    {
        name: "Login",
        path: "/login",
        element: <Login />,

    },
    {
        name: "Register",
        path: "/register",
        element: <Register />,

    },

];