import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CustomButton } from "../../component/Custombutton";
import { useBearStore } from "../../store";
import { Box, InputAdornment, TextField } from "@mui/material";
import shallow from 'zustand/shallow'
import { z } from "zod";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { config } from "../../config";
import UseSnackbar from "../../component/SnackBar";
import SimpleBackdrop from "../../component/backdrop";

const loginSchema = z.object({
    email: z.string({
                required_error: "Email is required",
                invalid_type_error: "Email must be a string",
    }).email({
        message: "Invalid email address"
    }),
    password: z.string().min(6, {message:'Password must be between 6 to 20 characters'})
        .max(20, {message:'Password must be between 6 to 20 characters'}),
    
});

type loginInput = z.infer<typeof loginSchema>;

const Login = () => {
    const toggleSnack = UseSnackbar()

    const [btnLoading, setBtnLoading] = useState(false);
    const [steps, setSteps] = useState(0);

    // useEffect(()=>{
        
        
    //     localStorage.clear()
    // },[])

    let { setUser } = useBearStore(
        (state: { setUser: any; }) => ({ setUser: state.setUser }),
        shallow
    );

    const navigate = useNavigate();
    const {
        register,
        formState: { errors, isSubmitSuccessful },
        handleSubmit,
    } = useForm<loginInput>({ resolver: zodResolver(loginSchema), });

    const onSubmitLoginData = (data: loginInput) => {
        const email = data.email;
        const password = data.password;
        
        setBtnLoading(true);
        fetch(`${config.baseUrl}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                password,
                steps

            })
        }).then(res => res.json())
            .then(response => {
                setBtnLoading(false);
                if (!response.ok) return toggleSnack(response.message);
                localStorage.setItem("user", `${JSON.stringify({user:response.user,token:response.token})}`);
                localStorage.setItem("steps", `${JSON.stringify({steps:response})}`);
                setUser(response.user);
                navigate("/biodata");
            }).catch((err) => {
                setBtnLoading(false);
                toggleSnack(err.message);
            })
    };

    return (
        <React.Fragment>
            <SimpleBackdrop open={btnLoading} setOpen={setBtnLoading}/>
            <Box
                component='form'
                onSubmit={handleSubmit(onSubmitLoginData)}
            >
                <TextField
                    variant="outlined"
                    label="email"
                    size="small"
                    type="tel"
                    required
                    error={!!errors['email']}
                    helperText={errors['email'] ? errors['email'].message : ''}
                    {...register('email')}
                    fullWidth
                    sx={{
                        mb: 2,
                    }}
                />
                <TextField
                    variant="outlined"
                    label="Password"
                    size="small"
                    type="password"
                    required
                    error={!!errors['password']}
                    helperText={errors['password'] ? errors['password'].message : ''}
                    {...register('password')}
                    fullWidth
                    sx={{
                        mb: 2

                    }}
                />
                <CustomButton loading={btnLoading} fullWidth type="submit">
                    Login
                </CustomButton>
            </Box>
            <span style={{
                textDecoration: "none",
                textAlign: "center",
                color: "green",
                fontSize: "13px",
            }} >
                Need an account?
                <Link style={{
                    textDecoration: "none",
                    textAlign: "center",
                    color: "green",
                    fontSize: "12px",
                    fontWeight: "bolder"
                }}
                    to={"/register"}> Click Here
                </Link>
            </span>
            <span style={{
                textDecoration: "none",
                textAlign: "center",
                color: "grey",
                fontSize: "13px",
            }} >
                `${"Contact us"  }`
                <Link style={{
                    textDecoration: "none",
                    textAlign: "center",
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "bolder"
                }}>
                    support@ndeportal.ng
                </Link>
            </span>
        </React.Fragment>
    );
}

export default Login;