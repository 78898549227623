import React from 'react'
import { useLocation, Navigate } from 'react-router-dom';
import { useBearStore } from '../store';

function WithAuth({children}:{children:JSX.Element}) {
    let {user} = useBearStore()
    let location = useLocation();
  
    if (!user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    
  
    return children;
}

export default WithAuth