import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import { useState } from "react";
import { programmeList } from "../../assets/programmeList";
import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomButton } from "../../component/Custombutton";
import { useBearStore } from "../../store";
import UseSnackbar from "../../component/SnackBar";
import { config } from "../../config";
import SimpleBackdrop from "../../component/backdrop";

const programmeSchema = z.object({
    skillchoice: z.string(),
    programme: z.string(),
    carrerinterest: z.string().min(6, '6 or more characters'),
});

type programmeInput = z.infer<typeof programmeSchema>;

const Programme = () => {
    const { NextPage, PreviousPage } = useBearStore();
    const [skillchoice, setSkillChoice] = useState<any>('');
    const [programme, setProgramme] = useState<any>('');
    const [carrerinterest, setCarrerInterest] = useState<any>('');
    const [programParticipated, setProgramParticipated] = useState<any>('');
    const [participated, setParticipated] = useState<any>(false);
    const [steps, setSteps] = useState(3);
    const [btnLoading, setBtnLoading] = useState(false);
    const toggleSnack = UseSnackbar()

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<programmeInput>({ resolver: zodResolver(programmeSchema), });

    const onSubmitProgrammeData = (data: programmeInput) => {

        let userDetail:any = localStorage.getItem('user')
        const token = JSON.parse(userDetail).token
        setBtnLoading(true)

        fetch(`${config.baseUrl}/programme`,{
                method:"POST",
            body:JSON.stringify({
                skillchoice,
                programme,
                carrerinterest,
                steps,
                programParticipated,
                }),
                headers:{
                    "Content-Type":"application/json",
                    "x-auth-token":`${token}`
                }
            }).then(async res=>{
                let result = await res.json()
                setBtnLoading(false)
               
                const{ok} = result
                if(ok){
                    localStorage.setItem("steps", `${JSON.stringify({steps:result})}`);
                    toggleSnack("Programme successfully updated","success")
                    NextPage();
                }
            }).catch(err=>{
                
                toggleSnack(err.message)
                setBtnLoading(false)
            })
        

    }

    return (
        <>
        <SimpleBackdrop open={btnLoading} setOpen={setBtnLoading}/>
        <Grid container flexDirection={"column"} flexWrap={"nowrap"} gap={2} >
            <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign={"center"}
            >
                Programme
            </Typography>
            <Box
                component='form'
                onSubmit={handleSubmit(onSubmitProgrammeData)}
            >
                <Grid container item flexDirection={"column"} flexWrap={"nowrap"} gap={2}>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            id="demo-select-small"
                            label="Skill Choice"
                            select
                            required
                            size="small"
                            value={skillchoice}
                            error={!!errors['skillchoice']}
                            helperText={errors['skillchoice'] ? errors['skillchoice'].message : ''}
                            {...register('skillchoice', {
                                onChange: (e) => setSkillChoice(e.target.value)
                            })}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {programmeList.map((skill) => (
                                <MenuItem key={skill.programe} value={skill.programe}>{skill.programe}</MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            select
                            required
                            id="demo-select-small"
                            label="Programme"
                            size="small"
                            value={programme}
                            error={!!errors['programme']}
                            helperText={errors['programme'] ? errors['programme'].message : ''}
                            {...register('programme', {
                                onChange: (e) => setProgramme(e.target.value)
                            })}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {programmeList.map(id => id.programe === skillchoice && (id.skills.map(skill => (<MenuItem value={skill}>{skill}</MenuItem>))))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size={"small"}
                            required
                            label="Career Interest"
                            value={carrerinterest}
                            error={!!errors['carrerinterest']}
                            helperText={errors['carrerinterest'] ? errors['carrerinterest'].message : ''}
                            {...register('carrerinterest', {
                                onChange: (e) => setCarrerInterest(e.target.value)
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox />}
                            label="I have participated in NDE program before"
                            labelPlacement="end"
                            onChange={() => setParticipated(!participated)}
                        />
                    </Grid>
                    {participated ? (<Grid item xs={12} >
                        <TextField
                            sx={{ width: "100%" }}
                            id="demo-select-small"
                            label="Program"
                            size="small"
                            select
                            required={participated}
                            value={programParticipated}
                            onChange={(e) => setProgramParticipated(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {programmeList.map((program) => (
                                <MenuItem key={program.programe} value={program.programe}>{program.programe}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>) : null
                    }

                </Grid>

                <Grid container flexDirection={"row"} flexWrap={"nowrap"} justifyContent={"space-between"} mt={{ xs: 3, sm: 16 }}>
                    <Button
                        variant="text"
                        onClick={PreviousPage}
                        sx={{ color: "green" }}
                    > Back
                    </Button>
                    <CustomButton
                        type="submit"
                        // disabled={button}
                        // loading={btnLoading}
                        disableFocusRipple
                        disableRipple
                    >
                        Next
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
        </>
    );
}

export default Programme;